<template>
  <div class="menu">
    <div
      :class="['menu-item', { active: index == activeIndex }]"
      v-for="(item, index) in data"
      :key="index"
      @click="onChange(item, index)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
    data: {
      type: Array,
      default: () => [
        {
          name: "领取记录",
          url: "/tao/memberBenefits/record/collection/collection",
        },
        {
          name: "扣费记录",
          url: "/tao/memberBenefits/record/deduction/deduction",
        },
      ],
    },
  },
  data() {
    return {};
  },
  methods: {
    onChange(item) {
      const { search = "" } = location;
      this.$router.replace({ path: item.url + search });
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  width: 750px;
  height: 82px;
  line-height: 82px;
  background: #ffffff;
  display: flex;

  &-item {
    flex: 1;
    text-align: center;
    position: relative;
    font-size: 32px;
    color: #999999;
    &.active {
      color: #333333;
      &::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 3px;
        background: #333333;

        border-radius: 2px;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
  }
  // align-items: center;
}
</style>
