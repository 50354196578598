<template>
  <div class="null">
    <img
      class="img"
      src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/null.png"
    />
		<div class="msg">{{ msg }}</div>
  </div>
</template>

<script>
export default {
	props: {
		msg: {
			type: String,
			default: '您还没有相关的记录哦',
		}
	},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.null {
	padding-top: 88px;
	text-align: center;

  .img {
    width: 354px;
  }

	.msg {
		padding-top: 15px;
		font-size: 28px;
		color: #999999;
	}
}
</style>
