<template>
  <div class="collection">
    <base-navigation-bar
      :isLeft="true"
      title="领取记录"
      color="#000000"
      @handleBack="handleBack"
      returnArrow="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/returnArrow.png"
    />
    <base-menu :activeIndex="0" />
    <base-null v-if="list.length === 0" />
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
        loading-text="正在加载..."
      >
        <div class="line" v-for="(item, index) in list" :key="index">
          <div class="title">{{ item.item_long_name }}</div>
          <div class="phone">领取号码：{{ item.phone_number }}</div>
          <div class="time">领取时间：{{ item.exchange_time }}</div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import BaseMenu from "../components/VMenu/index.vue";
import BaseNull from "@/components/BaseNull/BaseNull.vue";
import BaseNavigationBar from "@/components/BaseNavigationBar/BaseNavigationBar.vue";
import { getRecord } from "@/api/tao.js";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseMenu,
    BaseNull,
    BaseNavigationBar,
  },
  data() {
    return {
      list: [],
      loading: false, // 控制加载状态
      finished: false, // 是否已全部加载完毕
      page: 0, // 当前页数，默认从第1页开始
    };
  },
  computed: {
    ...mapGetters(["member_token"]),
  },
  methods: {
    async onLoad() {
      this.loading = true;

      try {
        this.page++; // 更新当前页数，准备请求下一页数据
        const res = await this.getList();
        this.list = [...this.list, ...res.record];
        // 检查是否有更多数据，可以根据 res 中的 meta 或者其他字段判断
        if (this.page == res.pages) {
          // 加载完毕
          this.finished = true;
        } else {
          this.finished = false;
        }
      } catch (error) {
        // console.error("加载失败:", error);
      } finally {
        this.loading = false;
      }
    },
    async getList() {
      try {
        const res = await getRecord(this.member_token, {
          page: this.page,
          data_type: "exchange",
        });
        return res;
      } catch (error) {
        this.finished = true;
      }
    },
    handleBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.collection {
  background-color: #f5f5f5;
  min-height: 100%;
  .navigation-bar {
    border-bottom: 1px solid #f5f5f5;
    background-color: #ffffff;
  }
  .content {
    padding: 30px;

    .line {
      padding: 20px 38px;
      margin-bottom: 24px;
      border-radius: 18px;
      background-color: #ffffff;
      box-sizing: border-box;
      min-height: 185px;

      .title {
        font-size: 32px;
        padding-bottom: 20px;
      }

      .phone {
        padding-bottom: 6px;
      }

      .phone,
      .time {
        font-size: 26px;
        font-weight: 400;
        color: #999999;
        line-height: 37px;
      }
    }
  }
}
</style>
